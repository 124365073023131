
import React from 'react';
import AttachFile from '@material-ui/icons/AttachFile';
import HighlightOff from '@material-ui/icons/HighlightOff'
import Popover from 'react-simple-popover'
import IconButton from '@material-ui/core/IconButton';
import Modal from 'react-modal';
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import moment from 'moment'
registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileEncode);


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};
export class CustomActions extends React.Component {
  receiverMobileNumber = "";
  constructor(props) {
    super(props);
    this._images = [];

    this.state = {
      loggedIn: props.loggedIn,
      socket: props.socket,
      name: props.name,
      isGroup: props.isGroup,
      modalIsOpen: false,
      files: [{
        source: 'index.html',
        options: {
          type: 'local'
        }
      }],
    };
    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleFilePondInit() {
    console.log('FilePond instance has initialised', this.pond);
  }

  getNewMessage(messageId, messageText, createdAt, image, userId, userName) {
    return {
      id: messageId,
      text: messageText,
      createdAt: createdAt,
      image: image,

      user: {
        id: userId,
        name: this.state.isGroup == "true" ? '' : userName,
      },
      renderAvatarOnTop: true
    };
  }

  renderPopover() {

    return (

      <Modal
        isOpen={this.state.modalIsOpen}
        onAfterOpen={this.afterOpenModal}
        onRequestClose={this.closeModal}
        style={customStyles}
        contentLabel="Send a File" >


        <h2 style={{ color: 'black' }} ref={subtitle => this.subtitle = subtitle}><span style={{ color: 'black' }}>Send a Photo/Video</span>
          <IconButton onClick={this.closeModal.bind(this)} style={{ marginLeft: '200px' }} edge="end" color="black" aria-label="menu">
            <HighlightOff />
          </IconButton>



          <FilePond
            oninit={() => this.handleFilePondInit()}
            ref={ref => this.pond = ref}
            allowFileTypeValidation={true}
            acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']}
            allowFileEncode={true}
            onupdatefiles={(fileItems) => {
              // console.log("Send Files ", fileItems)
              try {
                var ext = fileItems[0].fileExtension
                var imageName = ''
                if (ext == "png" || ext == "jpg" || ext == "jpeg") {
                  imageName = 'IMG_' + moment(new Date()).format('YYYY_MM_DD_HH_mm_ss');
                }
                else if (ext == "pdf") {
                  imageName = 'FILE_' + moment(new Date()).format('YYYY_MM_DD_HH_mm_ss');
                }
                const newMessageArray = [];
                const newMessage = {
                  id: "45621489" + new Date(),
                  text: (ext == "png" || ext == "jpg" || ext == "jpeg") ? "Image Attachment" : 'https://auth.talkzee.net:4443/media/' + this.state.loggedIn + '_' + this.receiverMobileNumber + '/' + imageName + '.' + ext,
                  createdAt: new Date(),
                  image: '',
                  user: {
                    id: this.state.loggedIn,
                    name: this.state.name,
                  },
                  renderAvatarOnTop: true
                };
                newMessageArray.push(newMessage)



                this.state.socket.send({
                  type: 'message',
                  receiverMobileNumber: this.receiverMobileNumber,
                  senderMobileNumber: this.state.loggedIn,
                  message: newMessageArray,
                  imageBase64: fileItems[0].getFileEncodeBase64String(),
                  imageName: imageName,
                  ext: ext,
                  isGroup: this.state.isGroup,
                  myName: this.state.name,
                });
                alert("File sent successfully")
                this.setState({ modalIsOpen: false });
                window.location.reload()
              }
              catch (e) {
                console.log("caught ", e)
              }
              // Set current file objects to this.state
              // this.setState({
              //     files: fileItems.map(fileItem => fileItem.getFileEncodeBase64String())
              // });
            }}



          />

        </h2>

      </Modal>

    )

  }


  render() {

    this.receiverMobileNumber = this.props.receiverMobileNumber
    return (
      <div >
        <div style={{ marginTop: '-10px' }}>{this.renderPopover()}</div>
        <div style={{ marginTop: '-35px', marginLeft: '10px' }}>
          <IconButton onClick={this.openModal.bind(this)} edge="end" color="inherit" aria-label="menu">
            <AttachFile />
          </IconButton>


        </div>
      </div>
    );
  }
}

