import React, { Component } from 'react';
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { GiftedChat } from 'react-web-gifted-chat'
import { CustomActions } from '../screens/CustomActions'
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText";

import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from '@material-ui/core/Tooltip';
import ListSubheader from '@material-ui/core/ListSubheader';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import ChatIcon from '@material-ui/icons/Chat';
import CallIcon from '@material-ui/icons/Call';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import InfoIcon from '@material-ui/icons/Info';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import StyledHashtag from "react-hashtag"
import Modal from 'react-modal';
import HighlightOff from '@material-ui/icons/HighlightOff'
import ReactTable from "react-table";
import "react-table/react-table.css";
import ReactExport from "react-export-excel";
import "./styles.css";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

import 'react-phone-input-2/dist/style.css'
import { withStore } from "@spyna/react-store";
import PhoneInput from 'react-phone-input-2';
import Sound from 'react-sound';
import moment from 'moment';
import { ShareSocial } from 'react-share-social'
import { browserName } from "react-device-detect";
import DialpadIcon from '@material-ui/icons/Dialpad';
import ReactDialpad from 'react-dialpad';

import GoogleMap from './MapScreen';

var dateFormat = require('dateformat');
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const AvailableHashTags = [{ name: '#ActionItem' }, { name: '#PunchedIn' }, { name: '#PunchedOut' }, { name: '#Reminder' }, { name: '#Requirements' }, { name: '#SickLeave' }, { name: '#Vacation' }, { name: '#Errors' }]
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '0px',
    height: '80vh'
  }
};

var options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

function errors(err) {
  console.warn(`ERROR(${err.code}): ${err.message}`);
}
class MainScreen extends Component {

  constructor(props) {
    super(props)
    const initialState = JSON.parse(localStorage.getItem('stateOfMyApp'))
    if (initialState === null)
      window.location.href = "/"
    this.state = {
      userLatitude: 0,
      userLongitude: 0,
      locationData: [],
      socket: this.props.socket,
      messages: [],
      loggedIn: initialState['loggedIn'],
      name: initialState['name'],
      baseURL: this.props.baseURL,
      chatTabArray: [],
      tempChatTabArray: [],

      receiverMobileNumber: "",
      selectedChatName: "",
      selectedChatStatus: "Away",
      isGroup: "false",
      groupMembersArray: [],
      modalIsOpenHashTag: false,
      modalIsOpenNewChat: false,
      modalIsOpenMeetingLink: false,
      modalIsOpenNewGroup: false,
      selectedSystemHashTag: "",
      hashTagData: "",
      appContactListArray: [],
      tempContactListArray: [],
      selectedContacts: [],
      modalSource: "",
      sendImage: 0,
      modalIsOpenNewGroupHelper: false,
      groupName: '',
      phoneInput: '',
      pass: '',
      modalIsOpenIncomingCall: false,
      modalIsOpenOutgoingCall: false,
      callSender: '',
      callType: '',
      callLink: '',
      modalIsOpenMeetNow: false,
      meetNowLink: '',
      modalIsOpenAddContact: false,
      newContact: '',
      contactName: '',
      avaiableHashTagOpen: false,
      loading: false,
      loadEarlierM: true,
      newMessageReceived: false,
      unreadMessage: new Array(),
      useHashTag: "",
      callLogs: new Array(),
      refreshingCallLogs: false,
      modalIsOpenCallLogs: false,
      roomName: '',
      isOpenScheduleMeetNow: false,
      meetingDate: null,
      meetingTime: null,
      meetings: [],
      scheduleNowVisible: false,
      isOpenDialPad: false,
      menuOpen: false,
      moreOpen: false,
      mapOpen: false,
      mapShowUserLocation: false,
      groupInfoOpen: false,
      isHeaderNotificationEnabled: false,
      headerNotification: '',

    }
    this.onSend = this.onSend.bind(this)
    this.openModalHashTag = this.openModalHashTag.bind(this);

    this.closeModalHashTag = this.closeModalHashTag.bind(this);

    this.openModalNewChat = this.openModalNewChat.bind(this);
    this.closeModalNewChat = this.closeModalNewChat.bind(this);


    this.openModalNewGroup = this.openModalNewGroup.bind(this);

    this.closeModalNewGroup = this.closeModalNewGroup.bind(this);
    this.customActionHandler = this.customActionHandler.bind(this)
    this.openModalIncomingCall = this.openModalIncomingCall.bind(this)
    this.closeModalIncomingCall = this.closeModalIncomingCall.bind(this)
    this.openModalMeetNow = this.openModalMeetNow.bind(this)
    this.closeModalMeetNow = this.closeModalMeetNow.bind(this)
    this.openModalAddContact = this.openModalAddContact.bind(this)
    this.closeModalAddContact = this.closeModalAddContact.bind(this)
    this.onStateChanged = this.onStateChanged.bind(this);
    this.onActionInvoked = this.onActionInvoked.bind(this);
    this.onCallStarted = this.onCallStarted.bind(this);
    this.onCallEnded = this.onCallEnded.bind(this);


  }


  getNewMessage(messageId, messageText, createdAt, image, userId, userName, readReceipt, senderName) {
    return {
      id: messageId,
      text: messageText,
      createdAt: createdAt,
      image: image,

      user: {
        id: userId,
        name: this.state.isGroup == "true" ? senderName : userName,
      },
      renderAvatarOnTop: true,
      readReceipt: readReceipt
    };
  }

  customActionHandler(someValue) {
    this.setState({
      sendImage: 1
    })
  }



  logOut() {
    this.props.store.set('loggedIn', '')
      .then(() => {
        localStorage.setItem('stateOfMyApp', JSON.stringify(this.props.store.getState()));
      })
    this.props.store.set('name', '')
      .then(() => {
        localStorage.setItem('stateOfMyApp', JSON.stringify(this.props.store.getState()));
      })
    window.location.href = 'https://web.talkzee.net/'

  }

  renderLoading() {
    return (<div>Loading...</div>)
  }



  onSend(message) {
    const newMessageArray = [];
    const newMessage = {
      id: message[0].id,
      text: message[0].text,
      createdAt: message[0].createdAt,
      image: '',
      user: {
        id: message[0].user.id,
        name: this.state.name,
      },
      renderAvatarOnTop: true
    };
    this.setState({ messages: [newMessage, ...this.state.messages] })
    newMessageArray.push(newMessage)

    this.state.socket.send({
      type: 'message',
      receiverMobileNumber: this.state.receiverMobileNumber,
      senderMobileNumber: this.state.loggedIn,
      message: newMessageArray,
      imageBase64: '',
      imageName: '',
      isGroup: this.state.isGroup,
      myName: this.state.name
    });

  }

  onRead(message) {
    //console.log("onRead", message)
    this.state.socket.send({
      type: 'readReceipt',
      receiverMobileNumber: this.state.loggedIn,
      senderMobileNumber: this.state.receiverMobileNumber,
      message: message.latestMessage,
      readReceipt: 'read'
    });

  }

  startMeetNow() {
    this.setState({ isOpenScheduleMeetNow: false })
    this.state.socket.send({
      type: 'startMeetNow',
      receiverMobileNumber: this.state.receiverMobileNumber,
      senderMobileNumber: this.state.loggedIn,
      myName: this.state.name,
      meetingDate: this.state.meetingDate,
      meetingTime: this.state.meetingTime,
    });
  }



  startPrivateCall() {

    this.state.socket.send({
      type: 'startPrivateCall',
      receiverMobileNumber: this.state.receiverMobileNumber,
      senderMobileNumber: this.state.loggedIn,
      myName: this.state.name,
      receiverName: this.state.selectedChatName,
    });
  }
  startAudioCall() {
    if (this.state.isGroup == "false") {

      this.setState({ modalIsOpenOutgoingCall: true })
      this.state.socket.send({
        type: 'startAudioCall',
        receiverMobileNumber: this.state.receiverMobileNumber,
        senderMobileNumber: this.state.loggedIn,
        myName: this.state.name,
        receiverName: this.state.selectedChatName
      });
    }
    else {
      //console.log('isGroup = this.state.selectedChatName ', this.state.selectedChatName)
      this.setState({ modalIsOpenOutgoingCall: true })
      this.state.socket.send({
        type: 'startGroupCall',
        groupName: this.state.selectedChatName,
        senderMobileNumber: this.state.loggedIn,
      });
    }
  }


  startScreenshareCall() {
    this.state.socket.send({
      type: 'startScreenshareCall',
      receiverMobileNumber: this.state.receiverMobileNumber,
      senderMobileNumber: this.state.loggedIn,
      myName: this.state.name,
      receiverName: this.state.selectedChatName
    });
  }
  getParams = (url) => {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };


  componentDidMount() {

    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition((pos) => {
              var crd = pos.coords;
              this.setState({ userLatitude: crd.latitude, userLongitude: crd.longitude })
              this.state.socket.send({
                type: 'updateLocation',
                mobileNumber: this.state.loggedIn,
                currentLat: crd.latitude.toString(),
                currentLng: crd.longitude.toString(),
                locationUpdateTimeStamp: new Date().toString()
              });
            });
          } else if (result.state === "prompt") {
            navigator.geolocation.getCurrentPosition((pos) => {
              var crd = pos.coords;
              this.setState({ userLatitude: crd.latitude, userLongitude: crd.longitude })
              this.state.socket.send({
                mobileNumber: this.state.loggedIn,
                type: 'updateLocation',
                currentLat: crd.latitude.toString(),
                currentLng: crd.longitude.toString(),
                locationUpdateTimeStamp: new Date().toString()
              });
            }, errors, options);
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
          result.onchange = function () {
            // console.log(result.state);
          };
        });
    } else {
      alert("Location is not supported by this browser! Please use Google Chrome for best experience")
    }
    if (browserName != "Chrome") {
      this.setState({ isHeaderNotificationEnabled: true, headerNotification: 'Alert: For best experience please use Google Chrome as your browser!!' });
    }
    var params = this.getParams(window.location.href)


    if (this.state.loggedIn != '') {
      if (this.state.isGroup == 'true') {

        this.state.socket.send({
          type: 'groupMembers',
          groupId: this.state.receiverMobileNumber,
        });
      }
      this.state.socket.on(
        'groupMembers',
        function (message) {
          if (message.type == 'groupMembers') {
            //console.log('groupMembers', JSON.stringify(message))
            this.setState({ groupMembersArray: message.userDetails })
          }
        }.bind(this)
      )

      this.state.socket.on(
        'sendMeetNowToReceiver',
        function (message) {
          if (message.type == 'sendMeetNowToReceiver') {
            alert("Invited successfully")
          }
        }.bind(this)
      )

      this.state.socket.send({
        type: 'pushNotificationConnect',
        mobileNumber: this.state.loggedIn,
        pushNotification: false
      });
      this.state.socket.on(
        'pushNotificationConnect',
        function (data) {
          this.handleRefresh();
        }.bind(this)
      );

      this.state.socket.on(
        'getCallLogs',
        function (message) {

          if (message.type == 'getCallLogs' && message.callLogs) {
            this.setState({ callLogs: message.callLogs, refreshingCallLogs: false })
          }
        }.bind(this)
      );

      this.state.socket.on(
        'getMeetings',
        function (message) {
          //console.log("meetings ", message)
          if (message.type == 'getMeetings' && message.meetings) {
            this.setState({ meetings: message.meetings })
          }
        }.bind(this)
      );


      this.state.socket.on(
        'requestUserNameList',
        function (message) {

          if (message.type == 'requestUserNameList' && message.chatTabArray[0]) {

            if (this.state.newMessageReceived) {
              this.setState({ newMessageReceived: false }, () => {
                this.setState(
                  {
                    refreshing: false,
                    chatTabArray: message.chatTabArray,
                    tempChatTabArray: message.chatTabArray,
                    isGroup: message.chatTabArray[0].isGroup,
                    unreadMessage: [...this.state.unreadMessage, message.chatTabArray[0]],
                    receiverMobileNumber: message.chatTabArray[0].contact,
                  },
                  () => {

                    if (this.state.isGroup == "true") {
                      this.state.socket.send({
                        type: 'groupMembers',
                        groupId: this.state.receiverMobileNumber,
                      });
                    }
                    //console.log("message received ", this.state.chatTabArray)

                  }

                );
              })

            }
            else {
              this.setState(
                {
                  refreshing: false,
                  chatTabArray: message.chatTabArray,
                  tempChatTabArray: message.chatTabArray,
                  receiverMobileNumber: message.chatTabArray[0].contact,
                  isGroup: message.chatTabArray[0].isGroup,
                  selectedChatName: message.chatTabArray[0].name,
                },
                () => {
                  this.getOldPrivateMessages()
                  if (this.state.isGroup == "true") {
                    this.state.socket.send({
                      type: 'groupMembers',
                      groupId: this.state.receiverMobileNumber,
                    });
                  }
                  if (params.receiverMobileNumber && params.receiverMobileNumber != "" && params.receiverMobileNumber != "undefined") {

                    this.setState({ loadEarlierM: true, isGroup: false, receiverMobileNumber: params.receiverMobileNumber, selectedChatName: params.receiverName, messages: [] }, function () {
                      this.getOldPrivateMessages()
                    });
                  }


                }
              );
            }




          }
        }.bind(this)
      );







      this.state.socket.on(
        'requestOldPrivateMessages',
        function (oldMessage) {
          if (oldMessage.type == 'requestOldPrivateMessages') {
            this.setState({ loading: true })
            var receiverMobileNumber = "";
            //console.log(JSON.stringify(oldMessage));
            for (var i = oldMessage.messageList.length - 1; i >= 0; i--) {
              if (this.state.isGroup == "true") {
                receiverMobileNumber = oldMessage.messageList[i].groupId;
              }
              else {
                receiverMobileNumber = oldMessage.messageList[i].receiverMobileNumber;
              }


              if (oldMessage.messageList[i].senderMobileNumber == this.state.loggedIn) {
                //message sent by me
                const { messages } = this.state;
                var image = oldMessage.messageList[i].imageName != '' ? this.state.baseURL + 'images/' + oldMessage.messageList[i].senderMobileNumber + '_' + receiverMobileNumber + '/' + oldMessage.messageList[i].imageName : ''
                const newMessage = this.getNewMessage(oldMessage.messageList[i]._id, oldMessage.messageList[i].message, oldMessage.messageList[i].timeStamp, image, this.state.loggedIn, this.state.name, oldMessage.messageList[i].readReceipt, oldMessage.messageList[i].senderName)
                this.setState({ messages: [newMessage, ...this.state.messages] })
              }
              else {

                //message sent to me (reveived by me)
                const { messages } = this.state;
                var image = oldMessage.messageList[i].imageName != '' ? this.state.baseURL + 'images/' + oldMessage.messageList[i].senderMobileNumber + '_' + receiverMobileNumber + '/' + oldMessage.messageList[i].imageName : ''
                const newMessage = this.getNewMessage(oldMessage.messageList[i]._id, oldMessage.messageList[i].message, oldMessage.messageList[i].timeStamp, image, oldMessage.messageList[i].senderMobileNumber, this.state.selectedChatName, '', oldMessage.messageList[i].senderName)
                this.setState({ messages: [newMessage, ...this.state.messages] })

              }
            }
            this.setState({ loading: false })
          }
        }.bind(this)
      );
      this.state.socket.on(
        'requestOldPrivateMessagesAll',
        function (oldMessage) {

          if (oldMessage.type == 'requestOldPrivateMessagesAll') {
            this.setState({ loading: true })
            var receiverMobileNumber = "";
            //console.log(JSON.stringify(oldMessage));
            for (var i = 0; i < oldMessage.messageList.length; i++) {
              if (this.state.isGroup == "true") {
                receiverMobileNumber = oldMessage.messageList[i].groupId;
              }
              else {
                receiverMobileNumber = oldMessage.messageList[i].receiverMobileNumber;
              }

              if (oldMessage.messageList[i].senderMobileNumber == this.state.loggedIn) {
                //message sent by me
                const { messages } = this.state;
                var image = oldMessage.messageList[i].imageName != '' ? this.state.baseURL + 'images/' + oldMessage.messageList[i].senderMobileNumber + '_' + oldMessage.messageList[i].receiverMobileNumber + '/' + oldMessage.messageList[i].imageName : ''
                const newMessage = this.getNewMessage(oldMessage.messageList[i]._id, oldMessage.messageList[i].message, oldMessage.messageList[i].timeStamp, image, this.state.loggedIn, this.state.name, oldMessage.messageList[i].readReceipt, oldMessage.messageList[i].senderName)
                this.setState({ messages: [...this.state.messages, newMessage] })
              }
              else {

                //message sent to me (reveived by me)
                const { messages } = this.state;
                var image = oldMessage.messageList[i].imageName != '' ? this.state.baseURL + 'images/' + oldMessage.messageList[i].senderMobileNumber + '_' + oldMessage.messageList[i].receiverMobileNumber + '/' + oldMessage.messageList[i].imageName : ''
                const newMessage = this.getNewMessage(oldMessage.messageList[i]._id, oldMessage.messageList[i].message, oldMessage.messageList[i].timeStamp, image, oldMessage.messageList[i].senderMobileNumber, this.state.selectedChatName, '', oldMessage.messageList[i].senderName)
                this.setState({ messages: [...this.state.messages, newMessage] })

              }
            }
            this.setState({ loading: false })
          }
        }.bind(this)
      );
      this.state.socket.on(
        'message',
        function (currentMessage) {

          if (currentMessage.type == 'message') {
            //console.log("New Message Received on the client ", currentMessage.sender + " " + this.state.receiverMobileNumber)
            var image = currentMessage.imageName != '' ? this.state.baseURL + 'images/' + currentMessage.sender + '_' + currentMessage.receiver + '/' + currentMessage.imageName : ''
            //const newMessage=this.getMessage(currentMessage,0,currentMessage.userInput[0].senderMobileNumber,currentMessage.userInput)
            const newMessage = this.getNewMessage(currentMessage.userInput[0]._id, currentMessage.userInput[0].text, currentMessage.userInput[0].createdAt, image, currentMessage.sender, this.state.selectedName, '', currentMessage.senderName)
            //console.log("New Message Received is ", newMessage)
            if (currentMessage.isGroup == "false") {
              if (currentMessage.sender === this.state.receiverMobileNumber) {
                //console.log("Chat window open. Display the message")
                this.setState({ messages: [newMessage, ...this.state.messages] })
              }
              else {
                //console.log("Chat window not open. Add the recent messages")
                this.setState({ newMessageReceived: true }, () => {
                  this.handleRefresh()
                })

              }
            }
            else if (currentMessage.isGroup == "true") {
              if (currentMessage.groupId === this.state.receiverMobileNumber) {
                //console.log("Chat window open. Display the message")
                this.setState({ messages: [newMessage, ...this.state.messages] })

              }
              else {
                //console.log("Chat window not open. Add the recent messages")
                this.setState({ newMessageReceived: true }, () => {
                  this.handleRefresh()
                })

              }
            }
          }
        }.bind(this)
      );


      // for receiver		    
      this.state.socket.on(
        'startPrivateCall',
        function (currentMessage) {
          //console.log(JSON.stringify(currentMessage));
          this.setState({ callSender: currentMessage.senderName, callType: 'Video', callLink: currentMessage.userInput }, () => { this.openModalIncomingCall() })


        }.bind(this)
      );
      // for sender


      // for audio receiver                  
      this.state.socket.on(
        'startAudioCall',
        function (currentMessage) {
          try {

            // console.log("Audio call received ", JSON.stringify(currentMessage));
            this.setState({ callSender: currentMessage.senderName, callType: 'Audio', callLink: currentMessage.userInput, roomName: currentMessage.roomName }, () => {
              var newMessage = {
                id: '',
                image: "",
                readReceipt: "",
                renderAvatarOnTop: true,
                text: "Incoming Call From " + currentMessage.senderName,
                user: {
                  id: currentMessage.sender,
                  name: ""
                }
              }
              this.setState({ messages: [newMessage, ...this.state.messages] }, () => {
                this.openModalIncomingCall()
              })

            })
          }
          catch (e) {
            //console.log(e);
          }


        }.bind(this)
      );
      // for audio sender
      this.state.socket.on(
        'senderStartAudioCall',
        function (currentMessage) {
          try {
            this.setState({ modalIsOpenOutgoingCall: false }, () => {
              if (currentMessage.success == 1) {
                window.open(currentMessage.userInput, '_blank');
              }
              else {
                try {
                  var newMessageArray = []
                  const newMessage = {
                    id: '',
                    text: 'Missed call between ' + this.state.name + ' and ' + this.state.selectedChatName,
                    createdAt: '',
                    image: '',
                    user: {
                      id: this.state.receiverMobileNumber,
                      name: this.state.name,
                    },
                    renderAvatarOnTop: true
                  };
                  this.setState({ messages: [newMessage, ...this.state.messages] })
                  newMessageArray.push(newMessage)

                  this.state.socket.send({
                    type: 'message',
                    receiverMobileNumber: this.state.receiverMobileNumber,
                    senderMobileNumber: this.state.loggedIn,
                    message: newMessageArray,
                    imageBase64: '',
                    imageName: '',
                    isGroup: this.state.isGroup,
                    myName: this.state.name
                  });
                  alert(currentMessage.error);
                  window.location.reload()
                }
                catch (e) {
                  alert("Unavailable!");
                }
              }
              //window.location.href=currentMessage.userInput;
            })
          }
          catch (err) {
            //console.log(err)
          }
        }.bind(this)
      );


      this.state.socket.on(
        'senderStartGroupCall',
        function (currentMessage) {
          this.setState({ modalIsOpenOutgoingCall: false })
          alert('You will be now redirected to the call page where you can wait for others to join the call. If you are not redirected, plesae make sure that your browser allows pop up to open up.');
          window.open(currentMessage.userInput, '_blank');
        }.bind(this))

      this.state.socket.on(
        'startMeetNow',
        function (currentMessage) {
          //console.log(JSON.stringify(currentMessage));
          this.setState({ meetNowLink: currentMessage.userInput }, () => { this.openModalMeetNow() })

          //this.setState({ callSender: currentMessage.senderName, callType: 'Audio', callLink: currentMessage.userInput }, () => { this.openModalIncomingCall() })


        }.bind(this)
      );


      // for screenshare receiver                  
      this.state.socket.on(
        'startScreenshareCall',
        function (currentMessage) {
          //console.log(JSON.stringify(currentMessage));
          this.setState({ callSender: currentMessage.senderName, callType: 'Audio+Screenshare', callLink: currentMessage.userInput }, () => { this.openModalIncomingCall() })


        }.bind(this)
      );



      this.state.socket.on(
        'searchHashTag',
        function (oldMessage) {
          //console.log(JSON.stringify(oldMessage))
          var tempHashTagDataArray = [];
          for (var i = 0; i < oldMessage.messageList.length; i++) {
            var tempHashTagData = {
              name: this.state.selectedChatName,
              timestamp: this.getFormattedTime(oldMessage.messageList[i].timeStamp),
              message: oldMessage.messageList[i].message
            }
            tempHashTagDataArray.push(tempHashTagData)
          }
          this.setState(
            {
              hashTagData: tempHashTagDataArray,
            },
            () => {
              this.openModalHashTag()
            }
          );

        }.bind(this)
      );


      this.state.socket.on(
        'requestAppContactList',
        function (message) {
          if (message.type == 'requestAppContactList') {
            this.setState({
              refreshing: false,
              appContactListArray: message.appContactListArray,
            });
            this.state.tempContactListArray = message.appContactListArray;
            if (this.state.modalSource == "new_chat") {
              this.setState({ modalIsOpenNewChat: true })
            }
            else if (this.state.modalSource == "invite") {
              this.setState({ modalIsOpenMeetingLink: true })
            }
            else if (this.state.modalSource == "add_group_member") {
              this.setState({ modalAddGroupMember: true })
            }
            else {
              this.setState({ modalIsOpenNewGroup: true })
            }


          }
        }.bind(this)
      );

      this.state.socket.on(
        'deleteGroup',
        function (message) {
          //console.log("group created " + JSON.stringify(message));
          if (message.type == "deleteGroup" && message.success == true) {
            alert("Group has been deleted successfully")
            window.location.reload()

          }
        }.bind(this)
      );
      this.state.socket.on(
        'deleteMember',
        function (message) {
          //console.log("group created " + JSON.stringify(message));
          if (message.type == "deleteMember" && message.success == true) {
            alert("Member has been deleted successfully")
            window.location.reload()
          }
        }.bind(this)
      );
      this.state.socket.on(
        'addMember',
        function (message) {
          //console.log("group created " + JSON.stringify(message));
          if (message.type == "addMember" && message.success == true) {
            alert("Member has been added successfully")
            window.location.reload()
          }
        }.bind(this)
      );
      this.state.socket.on(
        'addMemberToNewMember',
        function (message) {
          //console.log("group created " + JSON.stringify(message));
          if (message.type == "addMemberToNewMember" && message.success == true) {
            window.location.reload()
          }
        }.bind(this)
      );
      this.state.socket.on(
        'createGroup',
        function (message) {
          //console.log("group created " + JSON.stringify(message));
          if (message.type == "createGroup" && message.success == true) {
            this.setState({ isGroup: true, receiverMobileNumber: message.groupId, selectedChatName: message.groupName, messages: [] }, function () {
              this.closeModalNewGroup()
              this.getOldPrivateMessages()
              window.location.reload()
            });
          }
          else {
            alert(message.error)
          }
        }.bind(this)
      );
      this.state.socket.on(
        'createGroupReceiver',
        function (message) {

          if (message.type == "createGroupReceiver" && message.success == true) {
            window.location.reload()
          }
        }.bind(this)
      );

      this.state.socket.on(
        'updateContactsFromWeb',
        function (message) {
          if (message.type == 'updateContactsFromWeb') {
            //console.log("Contact Added Successfully ", JSON.stringify(message))
            if (message.success == 1) {
              alert('Contact Uploaded Successfully!')
              this.setState({ isGroup: false, receiverMobileNumber: message.contact, selectedChatName: message.name, messages: [] }, function () {
                this.getOldPrivateMessages()
                this.closeModalNewChat()
              });

            }
            else {
              alert("Error! Contact already exists! ")
            }
            this.setState({ modalIsOpenAddContact: false })
          }
        }.bind(this)
      )


      this.state.socket.on(
        'readReceipt',
        function (message) {
          if (message.type == 'readReceipt') {
            this.getOldPrivateMessages()
            //console.log("readReceipt is received ", message)


          }
        }.bind(this)
      )

      this.state.socket.on('reconnectedSuccessfully',
        function (message) {
          if (message.type == 'reconnectedSuccessfully') {
            window.location.reload()


          }
        }.bind(this)
      )

      this.state.socket.on('deleteMessage',
        function (message) {
          if (message.type == 'deleteMessage') {
            //console.log("Delete Message request received on the client ")
            if (this.state.receiverMobileNumber == message.sender) {
              //console.log("Message deleted. Requesting the messages again from the server ")
              this.getOldPrivateMessages()
            }
            else {
              //console.log("Another chat window opened at the client ")
            }
          }
        }.bind(this)
      )


      this.state.socket.on(
        'getUserLocation',
        function (message) {
          if (message.type == 'getUserLocation') {
            this.setState({ locationData: message.locationData, mapOpen: true, mapShowUserLocation: false })
          }
        }.bind(this)
      )

    }




    this.sendHeartBeat()
    setInterval(this.sendHeartBeat, 60000);
    this.getMeetings()


  }

  sendHeartBeat = () => {
    try {
      //console.log("sent")
      this.state.socket.send({
        type: 'heartbeat',
        user1: this.state.loggedIn,
        user2: this.state.receiverMobileNumber,
        isGroup: this.state.isGroup,
        currentLat: this.state.userLatitude,
        currentLng: this.state.userLongitude
      });
    } catch (e) {
      //console.log(e);
    }
  }

  openModalMeetNow() {
    this.setState({ modalIsOpenMeetNow: true })
  }

  closeModalMeetNow() {
    this.setState({ modalIsOpenMeetNow: false, meetingDate: null, meetingTime: null });
  }

  openModalIncomingCall() {
    this.setState({ modalIsOpenIncomingCall: true })
  }
  closeModalIncomingCall() {
    this.setState({ modalIsOpenIncomingCall: false });
  }

  openModalOutgoingCall() {
    this.setState({ modalIsOpenOutgoingCall: true })
  }
  closeModalOutgoingCall() {
    this.setState({ modalIsOpenOutgoingCall: false });
  }





  startNewCall(callSender) {
    this.setState({ modalIsOpenIncomingCall: false });
    if (this.state.isGroup == "false") {

      this.state.socket.send({
        type: 'senderStartAudioCall',
        receiverMobileNumber: this.state.loggedIn,
        senderMobileNumber: this.state.receiverMobileNumber,
        myName: this.state.name,
        receiverName: callSender
      });

      this.setState({ modalIsOpenIncomingCall: false }, () => {
        window.open(
          this.state.callLink,
          '_blank' // <- This is what makes it open in a new window.
        );

      })
    }
    else {
      this.state.socket.send({
        type: 'senderStartGroupCall',
        senderMobileNumber: this.state.loggedIn,
        senderName: this.state.name,
        receiverName: callSender,
        roomName: this.state.roomName,
      });
    }


  }
  renderMeetNowModal() {

    return (
      <Modal
        isOpen={this.state.modalIsOpenMeetNow}

        onRequestClose={this.closeModalMeetNow}
        style={customStyles}
        contentLabel={"Setup Meeting"}>

        <div style={{ width: "600px" }}>
          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Meeting Details
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalMeetNow.bind(this)}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>

          <div style={{ textAlign: 'center', marginTop: '25px', marginBottom: '25px', width: '99%', padding: 20 }}>
            {this.state.meetingDate == null ?
              <h4>Meeting Date and Time : Now</h4>
              :
              <>
                <h4>Meeting Date {moment(this.state.meetingDate, "YYYY-MM-DD").format('DD MMM YYYY')}</h4>
                <h4>Meeting  {moment(this.state.meetingTime, "HH:mm").format('hh:mm A')}</h4>
                <h4>Send the following meeting invite to your friends to join the meeting. You can share the link on social media or copy it and share it on email.</h4>
                <Button onClick={() => {
                  this.setState({ modalSource: "invite" }, () => {
                    this.state.socket.send({
                      type: 'requestAppContactList',
                      receiver: this.state.loggedIn,
                      sender: this.state.loggedIn,
                    });
                  })


                  // this.startMeetNow()
                }} style={{ marginTop: '10px', fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
                  Invite your talkzee friends
                </Button>
              </>
            }




            <ShareSocial
              title={this.state.meetingDate == null ? 'Meeting Date Now' : 'Meeting Date ' + moment(this.state.meetingDate, "YYYY-MM-DD").format('DD MMM YYYY') + " Time " + moment(this.state.meetingTime, "HH:mm").format('hh:mm A')}
              url={this.state.meetNowLink}
              socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
            />
            <h4>You can join the call by clicking on the following button - </h4>
            <a target="_blank" href={this.state.meetNowLink}>Start Meeting</a>
          </div>
        </div>


      </Modal>
    );
  }

  onStateChanged(state) {
    //console.log('to state', state);
  }

  onActionInvoked(action, data) {
    switch (action) {
      case 'CALL_STARTED':
        this.onCallStarted(data.number);
        break;
      case 'CALL_ENDED':
        this.onCallEnded();
        break;
      default:
        break;
    }
  }

  onCallStarted(number) {
    //console.log('Calling...', number);
    window.callCustomer('+' + number)

  }

  onCallEnded() {
    window.hangUp()
    this.dialpad.endCall();
  }

  renderDialPadModal() {

    return (
      <Modal
        isOpen={this.state.isOpenDialPad}
        onRequestClose={() => { this.setState({ isOpenDialPad: false }) }}
        style={customStyles}
        contentLabel={"Dial Pad"}>

        <div style={{ padding: 10, backgroundColor: '#ffffff', color: '#000000' }}>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalOutgoingCall.bind(this)}>
              <HighlightOff style={styles.iconColor} />
            </IconButton>
          </div>
          <p>Please dial the number with the country code without adding +</p>
          <p>For eg: if you want to dial +12029531828 then please dial 12029531828</p>

          <ReactDialpad
            style={{ margin: 'auto', borderWidth: 1, borderStyle: 'solid', borderColor: '#000000', backgroundColor: '#ffffff' }}
            ref={dialpad => (this.dialpad = dialpad)}
            onStateChanged={this.onStateChanged}
            onActionInvoked={this.onActionInvoked}
          />
        </div>



      </Modal>
    );
  }

  renderOutgoingCallModal() {
    return (
      <Modal
        isOpen={this.state.modalIsOpenOutgoingCall}

        onRequestClose={this.closeModalOutgoingCall}
        style={customStyles}
        contentLabel={"Calling"}>

        <div style={{ width: "600px" }}>

          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Calling...
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalOutgoingCall.bind(this)}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <div style={{ textAlign: 'center', marginTop: '25px', marginBottom: '25px', width: '99%' }}>
            <Avatar style={{ margin: 'auto', width: '100px', height: '100px' }}>{this.getInitials(this.state.selectedChatName)}</Avatar>
            <p>{this.state.selectedChatName}</p>

          </div>
        </div>


      </Modal>
    );
  }

  renderIncomingCallModal() {

    return (
      <Modal
        isOpen={this.state.modalIsOpenIncomingCall}

        onRequestClose={this.closeModalInComingCall}
        style={customStyles}
        contentLabel={"New Incoming Call"}>

        <div style={{ width: "600px" }}>

          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Incoming {this.state.callType} Call
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalIncomingCall.bind(this)}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <div style={{ textAlign: 'center', marginTop: '25px', marginBottom: '25px', width: '99%' }}>
            <Avatar style={{ margin: 'auto', width: '100px', height: '100px' }}>{this.getInitials(this.state.callSender)}</Avatar>
            <p>{this.state.callSender}</p>
            <Button onClick={this.startNewCall.bind(this, this.state.callSender,)} variant="outlined" style={{ margin: '10px', color: '#ffffff', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)' }}>
              Accept
            </Button>
            <Button onClick={this.closeModalIncomingCall.bind(this)} variant="outlined" style={{ margin: '10px', color: '#ffffff', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)' }}>
              Reject
            </Button>
          </div>
        </div>


      </Modal>
    );
  }







  getOldPrivateMessages = () => {
    this.state.socket.send({
      type: 'requestOldPrivateMessages',
      user1: this.state.loggedIn,
      user2: this.state.receiverMobileNumber,
      isGroup: this.state.isGroup,
    });
  };

  handleRefresh = () => {
    //console.log("message received handleRefresh")
    this.setState(
      {
        refreshing: true,
      },
      () => {
        this.state.socket.send({
          type: 'requestUserNameList',
          receiver: this.state.loggedIn,
          sender: this.state.loggedIn,
        });
      }
    );
  };

  openCallLogs = () => {
    //console.log("message received openCallLogs")
    this.setState(
      {
        refreshingCallLogs: true,
        modalIsOpenCallLogs: true,

      },
      () => {
        if (window.innerWidth < 780) {
          this.state.menuOpen ? this.setState({ menuOpen: false }) : this.setState({ menuOpen: true })
        }
        this.state.socket.send({
          type: 'getCallLogs',
          mobileNumber: this.state.loggedIn,
        });
      }
    );
  };

  getMeetings = () => {
    this.state.socket.send({
      type: 'getMeetings',
      mobileNumber: this.state.loggedIn,
    });
  };




  getInitials(name) {
    if (name) {
      return name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
      // var initials = name.match(/\b\w/g) || [];
      // initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
      // return initials
    }
    else {
      return 'U'
    }
  }



  onChange = (event) => {
    const text = event.target.value
    var newData = this.state.tempChatTabArray.filter(function (item) {
      const filterName = item.name.toUpperCase().indexOf(text.toUpperCase()) > -1
      return filterName
    });

    this.setState({
      chatTabArray: newData,
    });


  }



  renderFlatListHeader = () => {
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <TextField
          id="standard-with-placeholder"
          label="Search"
          placeholder="Search"
          onChange={this.onChange.bind(this)}
          style={{ flex: 1, zIndex: 1 }}

          margin="normal"
        />
      </div>

    );
  };
  handleListItemClick = (event, item, removeUnread) => {
    this.setState({ loadEarlierM: true, isGroup: item.isGroup, receiverMobileNumber: item.contact, selectedChatName: item.name, messages: [], }, function () {
      this.getOldPrivateMessages()
      this.onRead(item)
      if (window.innerWidth < 780) {
        this.state.menuOpen ? this.setState({ menuOpen: false }) : this.setState({ menuOpen: true })
      }
      if (item.isGroup == "true") {
        this.state.socket.send({
          type: 'groupMembers',
          groupId: this.state.receiverMobileNumber,
        });
      }


    });

    if (removeUnread) {
      var array = this.state.unreadMessage;
      var index = array.indexOf(item); // Let's say it's Bob.
      delete array[index];
      //console.log("Nyra ", array)
      this.setState({ unreadMessage: array })

    }
  };


  handleListItemClickNewChat = (event, item) => {
    this.setState({ isGroup: false, receiverMobileNumber: item.contact, selectedChatName: item.name, messages: [] }, function () {
      this.getOldPrivateMessages()
      this.closeModalNewChat()
    });
  };

  renderInformationHeader() {
    return (
      <AppBar position="static" color="default" style={styles.headingCenter}>
        <Toolbar>
          <div style={{ color: 'white' }}>
            <Typography variant="h6" color="inherit">
              More
            </Typography>
          </div>
          <div style={{ marginLeft: 'auto', width: '10%' }} className="mobile-only">
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.state.moreOpen ? this.setState({ moreOpen: false }) : this.setState({ moreOpen: true }) }}>
              <CloseIcon style={styles.iconColor} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
    )
  }

  openHashTags() {
    if (this.state.availableHashTagsOpen === true) {
      this.setState({ availableHashTagsOpen: false })
    }
    else {
      this.setState({ availableHashTagsOpen: true })
    }

  }


  renderInformation() {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


    return (
      <div style={{ maxHeight: '90%', overflow: 'auto' }}>
        <ListItem
          button
          alignItems="flex-start" style={{ padding: '2', backgroundColor: '#f0f0f0', borderBottom: '1px solid #949494' }}>
          <ListItemAvatar>
            <Avatar>{this.getInitials(this.state.name)}</Avatar>
          </ListItemAvatar>
          <ListItemText primary={this.state.name}
            secondary={this.state.loggedIn}

          />
        </ListItem>
        <div style={{ textAlign: "center", marginTop: '20px' }}>
          <a href='https://healthcaresolutions4us.com/home/login' target="_blank"><Button style={{ background: '#fff', padding: '5px' }} variant="outlined">
            <img style={{ width: '100px' }} src="https://healthcaresolutions4us.com/external/images/logo.png"></img>
          </Button></a>
          <br />
          <Button onClick={() => {
            if (window.innerWidth < 780) {
              this.state.moreOpen ? this.setState({ moreOpen: false }) : this.setState({ moreOpen: true })
            }
            this.setState({ isOpenScheduleMeetNow: true })
            // this.startMeetNow()
          }} style={{ marginTop: '10px', fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
            Setup Meeting
          </Button>
          <Button onClick={() => {
            if (window.innerWidth < 780) {
              this.state.moreOpen ? this.setState({ moreOpen: false }) : this.setState({ moreOpen: true })
            }
            this.getUserLocation(this.state.loggedIn, "false", "")
            // this.startMeetNow()
          }} style={{ marginTop: '10px', fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
            Current Location
          </Button>
        </div>

        <List style={{ maxHeight: '100%', overflow: 'auto', zIndex: 0 }}>
          <ListSubheader className={'hashtag-buttom'}>
            <p style={{ color: 'black', fontSize: '18px', margin: 'auto', textAlign: 'center', cursor: 'pointer', borderBottom: '1px solid' }}
              onClick={this.openHashTags.bind(this)}>Available Hash Tags </p>

          </ListSubheader>
          <SlideDown className={'my-dropdown-slidedown'}>
            {this.state.availableHashTagsOpen && AvailableHashTags.map(item =>
              <ListItem


                alignItems="flex-start" style={{ padding: '2' }}>
                <ListItemText primary={item.name} />
                <ListItemText style={{ border: '1px solid black', textAlign: 'center', cursor: 'pointer' }} onClick={() => {
                  if (window.innerWidth < 780) {
                    this.state.moreOpen ? this.setState({ moreOpen: false }) : this.setState({ moreOpen: true })
                  }
                  this.setState({ useHashTag: item.name + moment(new Date()).format("YYYY-MM-DD") + " " })

                }} primary={"Use"} />
                <ListItemText style={{ border: '1px solid black', textAlign: 'center', marginLeft: 5, cursor: 'pointer' }} onClick={(event) => {
                  if (window.innerWidth < 780) {
                    this.state.moreOpen ? this.setState({ moreOpen: false }) : this.setState({ moreOpen: true })
                  }
                  this.handleSystemHashTagPress(item.name)
                }} primary={"Report"} />
              </ListItem>

            )
            }
          </SlideDown>
        </List>
        <List style={{ maxHeight: '100%', overflow: 'auto', zIndex: 0 }}>
          <ListSubheader className={'hashtag-buttom'}>
            <p style={{ color: 'black', fontSize: '18px', margin: 'auto', textAlign: 'center', cursor: 'pointer', borderBottom: '1px solid' }}
              onClick={() => {
                if (this.state.upcomingMeetingsOpen === true) {
                  this.setState({ upcomingMeetingsOpen: false })
                }
                else {
                  this.setState({ upcomingMeetingsOpen: true })
                }
              }}>Upcoming Meetings</p>

          </ListSubheader>
          <SlideDown className={'my-dropdown-slidedown'}>
            {this.state.meetings.length > 0 ?
              <div style={{ padding: 20 }}>
                {this.state.upcomingMeetingsOpen && this.state.meetings.map(item =>
                  <div style={{ borderWidth: 1, borderColor: 'black', borderStyle: 'solid', padding: 20 }}>
                    <p>{item.meetingDate && moment(item.meetingDate.split('T')[0], "YYYY-MM-DD").format('DD MMM YYYY') + ' ' + item.meetingTime} - {userTimeZone}</p>
                    <p>Share Link - {item.meetingLink}</p>
                    <Button onClick={() => { window.location.href = item.meetingLink }} style={{ marginTop: '10px', fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
                      Start Meeting
                    </Button>

                  </div>

                )
                }
              </div>
              :
              <ListItem alignItems="flex-start" style={{ padding: '2' }}>
                <ListItemText primary={'No upcoming meetings'} />
              </ListItem>
            }

          </SlideDown>

        </List>
        <div style={{ textAlign: "center", marginTop: '20px' }}>
          <Button onClick={() => this.logOut()} style={{ fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
            Logout
          </Button>
        </div>
      </div>
    )
  }



  renderChannelHeader() {
    return (

      <AppBar position="static" color="default" style={styles.bgGradient}>
        <Toolbar>
          <Tooltip title="New Group">
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.openModalNewGroup.bind(this)}>
              <GroupAddIcon style={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>

          <div style={{ marginLeft: 'auto', color: 'white' }}>
            <Typography variant="h6" color="inherit">
              Recent Chats
            </Typography>
          </div>

          <Tooltip title="Chat">
            <div style={{ marginLeft: 'auto' }}>
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.openModalNewChat.bind(this)}>
                <ChatIcon style={styles.iconColor} />
              </IconButton>
            </div>
          </Tooltip>
          <div style={{ marginLeft: 'auto', width: '10%' }} className="mobile-only">
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.state.menuOpen ? this.setState({ menuOpen: false }) : this.setState({ menuOpen: true }) }}>
              <CloseIcon style={styles.iconColor} />
            </IconButton>
          </div>

        </Toolbar>
      </AppBar>

    )
  }
  renderChatHeader() {
    return (
      <AppBar position="static" color="default" style={{ background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', width: '100%' }}>
        <Toolbar style={{ width: '100%', paddingRight: 0, paddingLeft: 0 }}>
          <div style={{ marginLeft: 'auto', width: '10%' }} className="mobile-only">
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.state.menuOpen ? this.setState({ menuOpen: false }) : this.setState({ menuOpen: true }) }}>
              <MenuIcon style={styles.iconColor} />
            </IconButton>
          </div>
          <Typography variant="h6" color="inherit" style={{ color: '#ffffff', backgroundColor: 'transparent', width: '25%', textAlign: 'left', marginLeft: 10, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {this.state.selectedChatName}
          </Typography>
          <div style={{ marginLeft: 'auto', width: '65%', textAlign: 'right', marginRight: 5 }} >
            <Tooltip title="Talkzee Call">
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.startAudioCall.bind(this)}>
                <ScreenShareIcon style={styles.iconColor} />
              </IconButton>
            </Tooltip>
            {this.state.loggedIn == "61452641992" || this.state.loggedIn == "19192717882" || this.state.loggedIn == "9193897323" ?
              <Tooltip title={'Phone Call  ' + this.state.receiverMobileNumber}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { window.callCustomer('+' + this.state.receiverMobileNumber) }}>
                  <CallIcon style={styles.iconColor} />
                </IconButton>
              </Tooltip>
              : null}
            {this.state.loggedIn == "61452641992" || this.state.loggedIn == "19192717882" || this.state.loggedIn == "9193897323" ?
              <Tooltip title={'Dial Pad'}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.setState({ isOpenDialPad: true }) }}>
                  <DialpadIcon style={styles.iconColor} />
                </IconButton>
              </Tooltip>
              : null}
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => {
              this.getUserLocation(this.state.receiverMobileNumber, this.state.isGroup, this.state.selectedChatName)

            }} >
              <LocationOnIcon style={styles.iconColor} />
            </IconButton>

            {this.state.isGroup == "true" ?
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.setState({ groupInfoOpen: true }) }} >
                <InfoIcon style={styles.iconColor} />
              </IconButton>
              : null}

            <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.state.moreOpen ? this.setState({ moreOpen: false }) : this.setState({ moreOpen: true }) }}>
              <MoreHorizIcon className="mobile-only" style={styles.iconColor} />
            </IconButton>



          </div>
        </Toolbar>
      </AppBar>
    );
  }


  renderCustomActions(props) {


    return <CustomActions {...props} receiverMobileNumber={this.state.receiverMobileNumber} socket={this.state.socket} loggedIn={this.state.loggedIn} name={this.state.name} addToMessage={this.addToMessage}
      isGroup={this.state.isGroup} customActionHandler={this.customActionHandler} />;
  }

  isValidURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  renderMessageText(props) {

    const { currentMessage: { text: currText } } = props;
    var parseTextStyle = props.position === 'left' ? "parseTextReceived" : "parseTextSent";
    const { currentMessage } = props
    var className = parseTextStyle;
    //console.log("render current message ", currText)
    return (


      <div className={className}>
        {this.isValidURL(currText) ?
          <>
            Link &nbsp;
            <a target="_blank" href={currText}>
              {'Click here to view'}
            </a>
          </>
          :
          <StyledHashtag onHashtagClick={val => this.handleSystemHashTagPress(val)}>
            {currText}
          </StyledHashtag>}

        {parseTextStyle == "parseTextSent" ?
          <div style={{ textAlign: 'right', height: 18 }}>
            <IconButton edge="end" className={'delete-icon'} color="inherit" aria-label="menu" onClick={this.onDeleteMessage.bind(this, props.currentMessage)}>
              <DeleteOutlineIcon style={{
                color: '#ffffff',
                backgroundColor: 'transparent', fontSize: 17
              }} />
            </IconButton>
            {currentMessage.readReceipt == "read" ?
              <IconButton edge="end" className={'delete-icon'} color="inherit" aria-label="menu" >
                <CheckCircleRoundedIcon style={{
                  color: '#ffffff',
                  backgroundColor: 'transparent',
                  fontSize: 17,
                  marginLeft: 10
                }} />
              </IconButton>
              : null}


          </div> : null}

      </div>
    );
  }

  openModalHashTag() {
    //console.log("Model opened called")
    this.setState({ modalIsOpenHashTag: true });
  }



  closeModalHashTag() {
    this.setState({ modalIsOpenHashTag: false });
  }

  openModalNewChat() {

    this.setState(
      {
        modalSource: "new_chat"
      },
      () => {
        this.state.socket.send({
          type: 'requestAppContactList',
          receiver: this.state.loggedIn,
          sender: this.state.loggedIn,
        });
      }
    );
    //console.log("Model opened called")

  }



  closeModalNewChat() {
    this.setState({ modalIsOpenNewChat: false });
  }


  openModalNewGroup() {
    //console.log("Model opened called Sahaj")
    this.setState(
      {
        modalSource: "new_group"
      },
      () => {
        this.state.socket.send({
          type: 'requestAppContactList',
          receiver: this.state.loggedIn,
          sender: this.state.loggedIn,
        });
      }
    );

  }



  closeModalNewGroup() {
    this.setState({ modalIsOpenNewGroup: false });
  }






  handleSystemHashTagPress(systemHashTag) {
    //console.log("systemHashTag is " + systemHashTag);
    this.setState(
      {
        selectedSystemHashTag: systemHashTag
      },
      () => {
        this.state.socket.send({
          type: 'searchHashTag',
          user1: this.state.loggedIn,
          user2: this.state.receiverMobileNumber,
          systemHashTag: this.state.selectedSystemHashTag,
          isGroup: this.state.isGroup
        });






      }
    );


  }



  getFormattedTime(time) {
    return dateFormat(time, "yyyy-mm-dd h:MM:ss")
  }



  getUserLocation(receiverMobileNumber, isGroup, groupName) {
    this.state.socket.send({
      type: 'getUserLocation',
      senderMobileNumber: this.state.loggedIn,
      receiverMobileNumber: receiverMobileNumber,
      isGroup: isGroup,
      groupName: groupName
    });

  }
  renderMap() {
    return (
      <Modal
        isOpen={this.state.mapOpen}
        onRequestClose={() => { this.setState({ mapOpen: false }) }}
        style={customStyles}
        contentLabel={"Map"} >
        <GoogleMap userLatitude={this.state.userLatitude} userLongitude={this.state.userLongitude} locationData={this.state.locationData} mapShowUserLocation={this.state.mapShowUserLocation}></GoogleMap>

      </Modal>
    )
  }

  renderGroupMembers() {
    //console.log("groupMembers", this.state.groupMembersArray)
    return (
      <Modal
        isOpen={this.state.groupInfoOpen}
        onRequestClose={() => { this.setState({ groupInfoOpen: false }) }}
        style={customStyles}
        contentLabel={"Group Info"} >
        <div style={{ flexDirection: 'row', display: 'flex' }}>
          <div style={{ padding: 10, flex: 1 }}>
            <Button style={{ backgroundColor: 'red', color: 'white' }} variant="outlined" onClick={this.deleteGroup.bind(this)}  >
              Delete Group
            </Button>
          </div>
          <div style={{ padding: 10, textAlign: 'right', flex: 1 }}>
            <Button variant="outlined" onClick={() => {

              this.setState({ modalSource: 'add_group_member' }, () => {
                this.state.socket.send({
                  type: 'requestAppContactList',
                  receiver: this.state.loggedIn,
                  sender: this.state.loggedIn,
                });
              })
            }}  >
              Add Members
            </Button>
          </div>
        </div>
        <div style={{ width: 600 }}>
          <List style={{ maxHeight: '100%', overflow: 'auto' }}>
            <ListSubheader>
              <TextField
                id="standard-with-placeholder"
                label="Group Members"
                disabled
                style={{ display: 'flex', flex: 1, borderBottom: '0px' }}
                margin="normal"
              />
            </ListSubheader>
            {this.state.groupMembersArray.map(item =>
              <div style={{ display: 'flex' }}>
                <ListItem
                  button
                  onClick={() => { }}
                  alignItems="flex-row" style={{ padding: '2', flexDirection: 'column' }}>

                  <ListItemText primary={item.memberName} />
                  <ListItemText secondary={'+' + item.memberMobileNumber} />
                  {item.isAdmin && (<ListItemText secondary={'Group Creator'} />)}



                </ListItem>
                <div style={{ padding: 10, textAlign: 'right', flex: 1 }}>
                  <Button variant="outlined" onClick={this.deleteMember.bind(this, item.memberMobileNumber)}  >
                    Delete
                  </Button>
                </div>
              </div>

            )
            }
          </List>
        </div>


      </Modal>
    )
  }

  renderPopOverHashTag() {
    return (
      <Modal
        isOpen={this.state.modalIsOpenHashTag}
        onAfterOpen={this.afteropenModalHashTag}
        onRequestClose={this.closeModalHashTag}
        style={customStyles}
        contentLabel={"Results of " + this.state.selectedSystemHashTag} >


        <AppBar position="static" color="default" style={styles.bgGradient}>
          <Toolbar>
            <div style={{ marginLeft: 'auto', color: 'white' }}>
              <Typography variant="h6" color="inherit">
                Results of {this.state.selectedSystemHashTag}
              </Typography>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalHashTag.bind(this)}>
                <HighlightOff style={styles.iconColor} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <ReactTable
          data={this.state.hashTagData}
          columns={[
            {
              columns: [
                {
                  Header: "Name",
                  accessor: "name",
                  width: 200

                },
                {
                  Header: "Message",
                  accessor: "message",
                  width: 300
                },
                {
                  Header: "Timestamp",
                  accessor: "timestamp",
                  width: 300
                },

              ]
            },
          ]}
          defaultPageSize={10}
          className="-striped -highlight"
        />
        <br />
        <ExcelFile element={<button>Download Data</button>}>
          <ExcelSheet data={this.state.hashTagData} name="HashTags">
            <ExcelColumn label="Name" value="name" />
            <ExcelColumn label="message" value="message" />
            <ExcelColumn label="TimeStamp" value="timestamp" />
          </ExcelSheet>

        </ExcelFile>



      </Modal>
    )
  }

  renderScheduleMeetNow() {
    const {
      scheduleNowVisible
    } = this.state
    return (
      <Modal
        isOpen={this.state.isOpenScheduleMeetNow}

        onRequestClose={() => { this.setState({ isOpenScheduleMeetNow: false }) }}
        style={customStyles}
        contentLabel={"Add Contact"}>

        <div style={{ width: "600px" }}>
          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Schedule A Meeting Now
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.setState({ isOpenScheduleMeetNow: false }) }}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>

          <div style={{ textAlign: 'center', margin: '25px auto', width: '60%' }}>
            <p>You can either start the meeting now or schedule it for a later date/time</p>

            <Button onClick={this.startMeetNow.bind(this)} style={{ marginTop: '25px', marginBottom: '25px', fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
              Start Now
            </Button>
            <p>OR</p>
            <Button onClick={() => { this.setState({ scheduleNowVisible: true }) }} style={{ marginTop: '25px', marginBottom: '25px', fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
              Schedule Now
            </Button>
            {scheduleNowVisible ?
              <>
                <p>Enter the date below in DD-MM-YYYY format to schedule the meeting</p>
                <input type="date" onChange={(e) => {
                  this.setState({ "meetingDate": e.target.value }, () => {
                  })

                }} value={this.state.meetingDate}></input>
                <p>Enter time below in hh:mm am format to schedule the meeting</p>
                <input type="time" onChange={(e) => {
                  this.setState({ "meetingTime": e.target.value }, () => {
                  })

                }} value={this.state.meetingTime}></input>
                <p>&nbsp;</p>
                <Button onClick={this.startMeetNow.bind(this)} style={{ marginTop: '25px', marginBottom: '25px', fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
                  Schedule Now
                </Button>
              </>
              : null}
          </div>
        </div>


      </Modal>

    )
  }

  onChangeNewChat = (event) => {
    const text = event.target.value
    var newData = this.state.tempContactListArray.filter(function (item) {
      const filterName = item.name.toUpperCase().indexOf(text.toUpperCase()) > -1
      return filterName
    });

    this.setState({
      appContactListArray: newData,
    });


  }


  renderFlatListHeaderNewChat = () => {
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <TextField
          id="standard-with-placeholder"
          label="Search"
          placeholder="Search"
          onChange={this.onChangeNewChat.bind(this)}
          style={{ flex: 1 }}

          margin="normal"
        />
      </div>

    );
  };


  onChangeNewGroup = (event) => {
    const text = event.target.value
    var newData = this.state.tempContactListArray.filter(function (item) {
      const filterName = item.name.toUpperCase().indexOf(text.toUpperCase()) > -1
      return filterName
    });

    this.setState({
      appContactListArray: newData,
    });


  }


  renderFlatListHeaderNewGroup = () => {
    return (
      <div style={{ display: 'flex', flex: 1 }}>
        <TextField
          id="standard-with-placeholder"
          label="Search"
          placeholder="Search"
          onChange={this.onChangeNewGroup.bind(this)}
          style={{ flex: 1 }}

          margin="normal"
        />
      </div>

    );
  };


  renderPopOverNewChat() {

    return (
      <Modal
        isOpen={this.state.modalIsOpenNewChat}
        onAfterOpen={this.afteropenModalNewChat}
        onRequestClose={this.closeModalNewChat}
        style={customStyles}
        contentLabel={"New Chat"}>

        <div style={{ width: "300px" }}>
          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Select a new contact
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalNewChat.bind(this)}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <List style={{ maxHeight: '100%', overflow: 'auto' }}>
            <ListSubheader>{this.renderFlatListHeaderNewChat()}</ListSubheader>
            {this.state.appContactListArray.map(item =>
              <ListItem
                button
                onClick={event => this.handleListItemClickNewChat(event, item)}
                alignItems="flex-start" style={{ padding: '2' }}>
                <ListItemAvatar>
                  <Avatar>{this.getInitials(item.name)}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name}
                  secondary={item.latestMessage}

                />
              </ListItem>

            )
            }
          </List>
        </div>


      </Modal>
    );
  }

  inviteFriends = (item) => {
    this.state.socket.send({
      type: 'sendMeetNowToReceiver',
      receiverMobileNumber: item.contact,
      receiverName: item.name,
      meetingDate: this.state.meetingDate,
      meetingTime: this.state.meetingTime,
      meetingLink: this.state.meetNowLink,
      senderMobileNumber: this.state.loggedIn,
    });
  }



  renderSendMeetingLink() {

    return (
      <Modal
        isOpen={this.state.modalIsOpenMeetingLink}
        onRequestClose={() => { this.setState({ modalIsOpenMeetingLink: false }) }}
        style={customStyles}
        contentLabel={"Invite your talkzee friends to join this meeting"}>

        <div style={{ width: "300px" }}>
          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Invite your talkzee friends
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.setState({ modalIsOpenMeetingLink: false }) }}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <List style={{ maxHeight: '100%', overflow: 'auto' }}>

            {this.state.appContactListArray.map(item => {


              return (
                <ListItem
                  button
                  onClick={event => this.inviteFriends(item)}

                  alignItems="flex-start" style={{ padding: '2' }}>
                  <ListItemAvatar>
                    <Avatar>{this.getInitials(item.name)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.name}

                  />
                </ListItem>
              )
            }

            )
            }
          </List>
        </div>


      </Modal>
    );
  }



  checkItem = (userCheckedItem) => {

    ////console.log("user checked item is "+JSON.stringify(userCheckedItem.mobileNumber));
    this.state.appContactListArray.map((item) => {
      if (item.contact === userCheckedItem.contact) {
        item.check = !item.check
        if (item.check === true) {


          this.setState(
            {
              selectedContacts: [...this.state.selectedContacts, item]
            },
            () => {
              //console.log('selected:' + item.name);
              //console.log("array", JSON.stringify(this.state.selectedContacts));
            });

        } else if (item.check === false) {
          const i = this.state.selectedContacts.indexOf(item)
          if (1 != -1) {
            this.state.selectedContacts.splice(i, 1)

            //console.log('unselect:' + item.name)
            this.forceUpdate()

          }
        }
      }
    })
    //console.log("array", JSON.stringify(this.state.selectedContacts));
    //this.setState({appContactListArray: this.state.appContactListArray})

  }


  renderPopOverNewGroup() {
    return (
      <Modal
        isOpen={this.state.modalIsOpenNewGroup}
        onAfterOpen={this.afteropenModalNewGroup}
        onRequestClose={this.closeModalNewGroup}
        style={customStyles}
        contentLabel={"Select contacts"}>
        {this.state.modalIsOpenNewGroupHelper == false ?
          <div style={{ width: "500px" }}>


            <AppBar position="static" color="default" style={styles.bgGradient}>
              <Toolbar>
                <div style={{ marginLeft: 'auto', color: 'white' }}>
                  <Typography variant="h6" color="inherit">
                    Add Group Members
                  </Typography>
                </div>
                <div style={{ marginLeft: 'auto' }}>

                  <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalNewGroup.bind(this)}>
                    <HighlightOff style={styles.iconColor} />
                  </IconButton>
                </div>
              </Toolbar>
            </AppBar>


            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div style={{ flex: 3 }}>
                <div style={{ flexDirection: 'row', padding: 5 }}>

                  {
                    this.state.selectedContacts.map((item, key) =>
                    (
                      <Button variant="outlined" onClick={() => this.checkItem(item)}>
                        {item.name}
                      </Button>
                    )
                    )
                  }
                </div>
              </div>
              <div style={{ flex: 1, textAlign: 'end', padding: 5 }}>
                {this.state.selectedContacts.length > 0 ?
                  <Button variant="outlined" onClick={() => this.setState({ modalIsOpenNewGroupHelper: true })}>
                    Next
                  </Button> :
                  null
                }
              </div>
            </div>
            <List style={{ maxHeight: '100%', overflow: 'auto' }}>
              <ListSubheader>{this.renderFlatListHeaderNewGroup()}</ListSubheader>
              {this.state.appContactListArray.map(item =>
                <ListItem
                  button
                  onClick={event => this.checkItem(item)}
                  alignItems="flex-start" style={{ padding: '2' }}>
                  <ListItemAvatar>
                    <Avatar>{this.getInitials(item.name)}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={item.name}
                    secondary={item.latestMessage}

                  />
                </ListItem>

              )
              }
            </List>


          </div>
          :
          this.renderPopOverNewGroupHelper()
        }


      </Modal>
    );
  }

  renderAddGroupMember() {
    return (
      <Modal
        isOpen={this.state.modalAddGroupMember}
        onRequestClose={() => { this.setState({ modalAddGroupMember: false }) }}
        style={customStyles}
        contentLabel={"Select contacts"}>
        <div style={{ width: "500px" }}>


          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Add Group Member
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => { this.setState({ modalAddGroupMember: false }) }}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div style={{ flex: 3 }}>
              <div style={{ flexDirection: 'row', padding: 5 }}>

                {
                  this.state.selectedContacts.map((item, key) =>
                  (
                    <Button variant="outlined" onClick={() => this.checkItem(item)}>
                      {item.name}
                    </Button>
                  )
                  )
                }
              </div>
            </div>
            <div style={{ flex: 1, textAlign: 'end' }}>
              {this.state.selectedContacts.length > 0 ?
                <div style={{ padding: 5 }}>
                  <Button variant="outlined" onClick={() => {
                    this.addMember(this.state.selectedContacts[0].contact)
                  }}>
                    Submit
                  </Button></div> :
                null
              }
            </div>
          </div>
          <List style={{ maxHeight: '100%', overflow: 'auto' }}>
            <ListSubheader>{this.renderFlatListHeaderNewGroup()}</ListSubheader>
            {this.state.appContactListArray.map(item =>
              <ListItem
                button
                onClick={() => {
                  //console.log("Test 1", item)
                  //console.log('Test 1 ', this.state.groupMembersArray)
                  let found = 0
                  for (let i = 0; i < this.state.groupMembersArray.length; i++) {
                    if (this.state.groupMembersArray[i].memberMobileNumber == item.contact) {
                      found = 1
                      break;
                    }
                  }
                  if (this.state.selectedContacts.length == 1) {
                    alert("Please add 1 memeber at a time.")
                  }
                  else if (found == 0) {
                    this.checkItem(item)
                  }
                  else {
                    alert("Member already a part of the group")
                  }

                }}
                alignItems="flex-start" style={{ padding: '2' }}>
                <ListItemAvatar>
                  <Avatar>{this.getInitials(item.name)}</Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name}
                  secondary={item.latestMessage}

                />
              </ListItem>

            )
            }
          </List>


        </div>
      </Modal>
    );
  }
  createGroup() {
    this.state.socket.send({
      type: 'createGroup',
      mobileNumber: this.state.loggedIn,
      groupName: this.state.groupName,
      groupMembers: this.state.selectedContacts
    })
  }

  deleteGroup() {

    this.state.socket.send({
      type: 'deleteGroup',
      groupId: this.state.receiverMobileNumber,
      mobileNumber: this.state.loggedIn,
      groupName: this.state.groupName,
      groupMembers: this.state.selectedContacts
    })
  }

  deleteMember(memberMobileNumber) {
    //console.log("groupMembers length", this.state.selectedContacts.length)
    this.state.socket.send({
      type: 'deleteMember',
      mobileNumber: this.state.loggedIn,
      groupId: this.state.receiverMobileNumber,
      memberMobileNumber: memberMobileNumber
    })
  }

  addMember(memberMobileNumber) {
    this.state.socket.send({
      type: 'addMember',
      mobileNumber: this.state.loggedIn,
      groupId: this.state.receiverMobileNumber,
      memberMobileNumber: memberMobileNumber
    })
  }

  renderPopOverNewGroupHelper() {
    return (
      <div style={{ width: "500px" }}>


        <AppBar position="static" color="default" style={styles.bgGradient}>
          <Toolbar>
            <div style={{ marginLeft: 'auto', color: 'white' }}>
              <Typography variant="h6" color="inherit">
                New Group
              </Typography>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalNewGroup.bind(this)}>
                <HighlightOff style={styles.iconColor} />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <div style={{ flexDirection: 'row', padding: 5 }}>
          {
            this.state.selectedContacts.map((item, key) =>
            (
              <Button variant="outlined">
                {item.name}
              </Button>
            )
            )
          }
        </div>
        <div style={{ padding: 10 }}>
          <TextField
            id="standard-with-placeholder"
            label="Group Name"
            placeholder="Enter Group Name"
            margin="normal"
            defaultValue={this.state.groupName}
            onChange={event => {
              const { value } = event.target;
              this.setState({ groupName: value });
            }}
            onChangeText={groupName => this.setState({ groupName })}
          />
        </div>
        {
          this.state.groupName ?
            <div style={{ padding: 5 }}>
              <Button variant="outlined" onClick={this.createGroup.bind(this)}  >
                Create Group
              </Button></div> : null

        }


      </div>
    );
  }



  uploadContactsFromWeb() {
    var newContact = this.state.newContact
    newContact = newContact.replace("+", "")
    newContact = newContact.replace("(", "")
    newContact = newContact.replace(")", "")
    newContact = newContact.replace("-", "")
    newContact = newContact.replace(/\s/g, '')
    this.state.socket.send({
      type: 'updateContactsFromWeb',
      receiverMobileNumber: this.state.receiverMobileNumber,
      senderMobileNumber: this.state.loggedIn,
      name: this.state.contactName,
      contact: newContact,

    });
  }
  openModalAddContact() {
    if (window.innerWidth < 780) {
      this.state.menuOpen ? this.setState({ menuOpen: false }) : this.setState({ menuOpen: true })
    }
    this.setState({ modalIsOpenAddContact: true })

  }

  closeModalAddContact() {
    this.setState({ modalIsOpenAddContact: false });
  }


  renderAddContactModal() {

    return (
      <Modal
        isOpen={this.state.modalIsOpenAddContact}

        onRequestClose={this.closeModalAddContact}
        style={customStyles}
        contentLabel={"Add Contact"}>

        <div style={{ width: "600px" }}>
          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Add New Contact
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={this.closeModalAddContact}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>

          <div style={{ textAlign: 'center', margin: '25px auto', width: '60%' }}>
            <p>Please enter the name and the mobile number of the the contact you want to upload to the system.</p>
            <TextField
              margin="normal"
              required
              fullWidth
              name="name"
              label="Name"
              type="text"
              id="name"
              variant="filled"
              defaultValue={this.state.contactName}
              onChange={event => {
                const { value } = event.target;
                this.setState({ contactName: value });
              }}
              onChangeText={contactName => this.setState({ contactName })}
              style={{ backgroundColor: 'white', borderRadius: '5px' }}
            />
            <PhoneInput
              style={{ width: '100%', marginBottom: '10px', }}
              defaultCountry={'us'}
              value={this.state.newContact}
              onChange={newContact => this.setState({ newContact })}
              preferredCountries={['us', 'au', 'in']}
            />
            {this.state.contactName != '' && this.state.newContact != '' &&
              (<Button onClick={this.uploadContactsFromWeb.bind(this)} style={{ marginTop: '25px', marginBottom: '25px', fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
                Save
              </Button>)}
          </div>
        </div>


      </Modal>
    );
  }

  renderCallLogsModal() {
    //console.log("renderCallLogsModal ", this.state.callLogs)
    return (
      <Modal
        isOpen={this.state.modalIsOpenCallLogs}

        onRequestClose={() => {
          this.setState({ modalIsOpenCallLogs: false })
        }}
        style={customStyles}
        contentLabel={"Call Logs"}>

        <div style={{ width: "800px" }}>
          <AppBar position="static" color="default" style={styles.bgGradient}>
            <Toolbar>
              <div style={{ marginLeft: 'auto', color: 'white' }}>
                <Typography variant="h6" color="inherit">
                  Call Logs
                </Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton edge="end" color="inherit" aria-label="menu" onClick={() => {
                  this.setState({ modalIsOpenCallLogs: false })
                }}>
                  <HighlightOff style={styles.iconColor} />
                </IconButton>
              </div>
            </Toolbar>
          </AppBar>

          <ReactTable
            data={this.state.callLogs}
            columns={[
              {

                columns: [
                  {
                    Header: "From",
                    accessor: "callerName",
                    width: 100

                  },
                  {
                    Header: "To",
                    accessor: "receiverName",
                    width: 100
                  },
                  {
                    Header: "Type",
                    accessor: "callType",
                    width: 100
                  },
                  {
                    Header: "Date",
                    accessor: "date",
                    width: 140
                  },
                  {
                    Header: "Start Time",
                    accessor: "startTime",
                    width: 100
                  },
                  {
                    Header: "End Time",
                    accessor: "endTime",
                    width: 100
                  },
                  {
                    Header: "Status",
                    accessor: "callStatus",
                    width: 100
                  },

                ]
              },
            ]}
            defaultPageSize={10}
            className="-striped -highlight"
          />

        </div>


      </Modal>
    );
  }

  loadEarlier(item1, item2, item3) {
    this.setState({ loadEarlierM: false })
    this.state.socket.send({
      type: 'requestOldPrivateMessagesAll',
      user1: this.state.loggedIn,
      user2: this.state.receiverMobileNumber,
      isGroup: this.state.isGroup,
    });
  }

  onDeleteMessage(message, message1) {
    if (!window.confirm("Are you sure you want to delete this message?"))
      return

    const newMessageArray = [];
    const newMessage = {
      id: message.id,
      text: message.text,
      createdAt: message.createdAt,
      image: message.image.match(/\/([^\/]+)$/)[1],
      user: {
        id: message.user.id,
        name: this.state.name,
      },
      renderAvatarOnTop: true
    };
    //this.setState({messages: [newMessage,...this.state.messages]})
    newMessageArray.push(newMessage)
    ////console.log(JSON.stringify(newMessageArray));

    var array = [...this.state.messages]; // make a separate copy of the array

    var index = array.findIndex(function (item, i) {
      return item.id === newMessage.id
    });

    if (index !== -1) {
      array.splice(index, 1);
      this.setState({ messages: array });
    }
    else {
      //console.log(array, newMessage)
    }

    this.state.socket.send({
      type: 'deleteMessage',
      receiverMobileNumber: this.state.receiverMobileNumber,
      senderMobileNumber: this.state.loggedIn,
      message: newMessageArray,
      imageBase64: '',
      imageName: '',
      isGroup: this.state.isGroup,
      myName: this.state.name
    });

  }



  renderLoading() {
    return (<div>Loading...</div>)
  }



  renderMainScreen() {
    const { useHashTag, menuOpen, moreOpen, isHeaderNotificationEnabled, headerNotification } = this.state
    return (
      <>
        {isHeaderNotificationEnabled ? <div style={{ position: 'sticky', backgroundColor: 'red', color: 'white', textAlign: 'center' }}>{headerNotification}</div> : null}
        <div style={styles.container}>
          {this.renderMap()}
          {this.renderGroupMembers()}
          {this.renderPopOverHashTag()}
          {this.renderPopOverNewChat()}

          {this.renderPopOverNewGroup()}
          {this.renderIncomingCallModal()}
          {this.renderOutgoingCallModal()}
          {this.renderDialPadModal()}
          {this.renderMeetNowModal()}
          {this.renderSendMeetingLink()}
          {this.renderAddContactModal()}
          {this.renderCallLogsModal()}
          {this.renderScheduleMeetNow()}
          {this.renderAddGroupMember()}
          <div style={styles.conversationList} className={!menuOpen ? "desktop-only" : null}>
            {this.renderChannelHeader()}
            <div style={{ textAlign: "center", marginTop: '20px' }}>
              <Button onClick={this.openModalAddContact.bind(this)} style={{ fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff' }} variant="outlined">
                Add Contact
              </Button>
              <Button onClick={this.openCallLogs.bind(this)} style={{ fontWeight: 'bold', background: 'linear-gradient(to right bottom, #51abc6, #0d3279)', color: '#fff', marginLeft: 5 }} variant="outlined">
                Call Logs
              </Button>
            </div>
            <List style={{ maxHeight: '80%', overflow: 'auto', paddingTop: 0, }}>
              <ListSubheader className="zIndex1" style={{ backgroundColor: 'white', zIndex: 1 }}>{this.renderFlatListHeader()}</ListSubheader>
              {this.state.chatTabArray.map(item => {
                if (this.state.unreadMessage.includes(item)) {
                  //console.log("message received true")
                  return (
                    <ListItem
                      button
                      onClick={event => this.handleListItemClick(event, item, true)}
                      alignItems="flex-start" style={{ padding: '2' }}>
                      <ListItemAvatar>
                        <Avatar>{this.getInitials(item.name)}</Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        style={{ color: '#1e4e8a' }}
                        primary={item.name}
                        secondary={<div>
                          <div style={{ fontWeight: 'bold', color: 'black' }}>{item.isGroup === "false" ? '+' + item.contact : null}</div>
                          <div style={{ fontWeight: 'bold', color: 'black' }}>{item.latestMessage.length > 18 ? item.latestMessage.substring(0, 18) + '...' : item.latestMessage}</div>
                        </div>}

                      />
                    </ListItem>)
                }
                else {
                  //console.log("message received false ", this.state.unreadMessage, item)
                  return (
                    <ListItem
                      button
                      onClick={event => this.handleListItemClick(event, item, false)}
                      alignItems="flex-start" style={{ padding: '2' }}>
                      <ListItemAvatar>
                        {item.isGroup === "false" && item.isOnline === true && (
                          <div class="circle" style={{ display: 'inline-block', borderRadius: '50%', backgroundColor: '#00d53b', position: 'absolute', zIndex: 1000, width: 10, height: 10 }} />
                        )}
                        <Avatar>
                          {this.getInitials(item.name)}
                        </Avatar>

                      </ListItemAvatar>
                      <ListItemText primary={item.name}
                        secondary={<div>
                          <div>{item.isGroup === "false" ? '+' + item.contact : null}</div>
                          <div>{item.latestMessage.length > 18 ? item.latestMessage.substring(0, 18) + '...' : item.latestMessage}</div>
                        </div>}

                      />
                    </ListItem>)
                }

              }

              )
              }
            </List>
          </div>
          <div style={{
            display: this.state.menuOpen || this.state.moreOpen ? 'none' : "flex",
            flex: 3,
            flexDirection: "column",
            borderWidth: "1px",
            borderColor: "#ccc",
            borderRightStyle: "solid",
            borderLeftStyle: "solid",
            width: '96%',
          }}>

            {this.renderChatHeader()}

            {this.state.loading === false ?
              <GiftedChat
                className="width100"
                user={{ id: this.state.loggedIn, }}
                messages={this.state.messages}
                onSend={this.onSend}
                text={useHashTag}
                onInputTextChanged={(text) => { this.setState({ useHashTag: text }) }}
                renderAvatarOnTop="true"
                renderActions={this.renderCustomActions.bind(this)}
                renderMessageText={this.renderMessageText.bind(this)}
                loadEarlier={this.state.loadEarlierM}
                onLoadEarlier={this.loadEarlier.bind(this)}
                renderLoading={this.renderLoading.bind(this)}
                showUserAvatar={true}
                showAvatarForEveryMessage={true}
                renderAvatar={(item) => {
                  return (
                    <div class="circle" style={{ display: 'inline-block', borderRadius: '50%', backgroundColor: '#00d53b' }}>
                      <p class="circle-inner" style={{
                        color: 'white',
                        display: 'table-cell',
                        verticalAlign: 'middle',
                        textAlign: 'center',
                        textDecoration: 'none',
                        height: 36,
                        width: 36,
                        fontSize: 18
                      }}>{this.getInitials(item.currentMessage.user.name)}</p>
                    </div>
                  )
                }}
              /> : <div style={{ textAlign: 'center', verticalAlign: 'middle', display: 'flex', margin: 'auto' }}>Loading Messages..</div>
            }

          </div>
          <div style={styles.conversationList} className={!moreOpen ? "desktop-only" : null}>

            {this.renderInformationHeader()}
            {this.renderInformation()}
          </div>
          <Sound
            url="https://talkzee.net/cool_sound.mp3"
            playStatus={this.state.modalIsOpenIncomingCall || this.state.modalIsOpenOutgoingCall ? Sound.status.PLAYING : Sound.status.STOPPED}
            playFromPosition={300 /* in milliseconds */}
            onLoading={this.handleSongLoading}
            onPlaying={this.handleSongPlaying}
            onResume={this.handleSongPlaying}
            onFinishedPlaying={this.handleSongFinishedPlaying}
            onError={() => { console.log("error") }}
          />

        </div >
      </>
    );
  }
  render() {
    return (
      this.state.loggedIn ? this.renderMainScreen() : null

    )
  }
}





const styles = {
  span: {
    color: "red"
  },
  loginScreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    background: 'linear-gradient(to right bottom, #51abc6, #0d3279)'
  },
  headingCenter: {
    alignItems: 'center',
    background: 'linear-gradient(to right bottom, #51abc6, #0d3279)'
  },
  bgGradient: {
    background: 'linear-gradient(to right bottom, #51abc6, #0d3279)'
  },
  iconColor: {
    color: '#ffffff',
    backgroundColor: 'transparent'
  },
  inline: {
    display: 'inline',
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    height: "100vh",
  },
  conversationList: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  chat: {
    display: "flex",
    flex: 3,
    flexDirection: "column",
    borderWidth: "1px",
    borderColor: "#ccc",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    width: '96%',
  },
  converationDetails: {
    display: 'flex',
    flex: 1,
  },
}
export default withStore(MainScreen);


