import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import logo from '../assets/icon-512.png'
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';


import Container from '@material-ui/core/Container';


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import { withStore } from "@spyna/react-store";




class LoginScreen extends Component {


  constructor(props) {

    super(props)

    this.state = {
      loggedIn: this.props.store.get("loggedIn"),
      name: this.props.store.get("name"),
      phoneInput: '',
      pass: '',
      socket: this.props.socket,
      loginViaAPI: false,
    }
  }
  getParams = (url) => {
    var params = {};
    var parser = document.createElement('a');
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };
  componentDidMount() {
    var params = this.getParams(window.location.href)
    this.state.socket.on(
      'password',
      function (data) {
        if (data.type == 'password') {
          if (data.success == true) {
            // console.log(data)
            this.props.store.set('loggedIn', data.mobileNumber)
              .then(() => {
                localStorage.setItem('stateOfMyApp', JSON.stringify(this.props.store.getState()));
              })
            this.props.store.set('name', data.name)
              .then(() => {
                localStorage.setItem('stateOfMyApp', JSON.stringify(this.props.store.getState()));
              })
            // console.log(this.props.store.getState())
            window.location.href = "/dashboard?receiverMobileNumber=" + params.receiverMobileNumber + "&receiverName=" + params.receiverName



          }
          else {
            alert("Inavlid username and password combination! Please try again");
          }

        }
      }.bind(this)
    );

    this.state.socket.on(
      'forgotPassword',
      function (data) {
        if (data.type == 'forgotPassword') {
          alert(data.message)

        }
      }.bind(this)
    );


    if (params.senderMobileNumber && params.senderName && params.senderMobileNumber != "" && params.senderName != "") {
      this.setState({ phoneInput: '' }, () => {
        this.setState({ phoneInput: "+" + params.senderMobileNumber, loginViaAPI: true })
      })
    }

  }
  loginClicked() {
    var phoneInput = this.state.phoneInput
    phoneInput = phoneInput.replace("+", "")
    phoneInput = phoneInput.replace("(", "")
    phoneInput = phoneInput.replace(")", "")
    phoneInput = phoneInput.replace("-", "")
    phoneInput = phoneInput.replace(/\s/g, '')
    this.state.socket.send({
      type: 'password',
      mobileNumber: phoneInput,
      pushToken: "",
      password: this.state.pass,
    });
  }

  forgotPassword() {
    var phoneInput = this.state.phoneInput
    phoneInput = phoneInput.replace("+", "")
    phoneInput = phoneInput.replace("(", "")
    phoneInput = phoneInput.replace(")", "")
    phoneInput = phoneInput.replace("-", "")
    phoneInput = phoneInput.replace(/\s/g, '')
    this.state.socket.send({
      type: 'forgotPassword',
      mobileNumber: phoneInput,
    });
  }





  signupClicked() {
    window.location.href = "/signup"
  }
  componentWillMount() {
    const initialState = JSON.parse(localStorage.getItem('stateOfMyApp'))
    if (initialState != null) {
      if (initialState['loggedIn'] != '' && initialState['name'] != '') {

        window.location.href = "/dashboard"
      }
    }
  }
  render() {
    const { loginViaAPI } = this.state;
    return (
      <div style={styles.loginScreen}>
        <Container style={{
          position: 'absolute', left: '50%', top: '40%',
          transform: 'translate(-50%, -50%)'
        }}
          component="main" maxWidth="xs">
          <CssBaseline />
          <div >
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img src={logo} style={{ width: 100 }} alt="Logo" />
            </div>
            {loginViaAPI ?
              <Typography component="h6" variant="h6" style={{ color: 'white', fontSize: 14, marginBottom: 10 }}>
                Please enter your talkzee password to authenticate your account
              </Typography>
              :
              <Typography component="h1" variant="h5" style={{ color: 'white' }}>
                Sign In
              </Typography>
            }
            <form noValidate>
              <PhoneInput
                style={{ width: '100%', marginBottom: '10px', }}
                defaultCountry={'us'}
                value={this.state.phoneInput}
                onChange={phoneInput => this.setState({ phoneInput })}
                preferredCountries={['us', 'au', 'in']}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                defaultValue={this.state.pass}
                onChange={event => {
                  const { value } = event.target;
                  this.setState({ pass: value });
                }}
                onChangeText={pass => this.setState({ pass })}




                style={{ backgroundColor: 'white', borderRadius: '5px' }}
              />

              {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
              <Button
                type="button"
                fullWidth
                variant="contained"

                color="primary"
                onClick={this.loginClicked.bind(this)}
                style={{ background: 'linear-gradient(to right bottom, #51abc6, #0d3279)' }}

              >
                Continue
              </Button>
              <Grid onClick={this.forgotPassword.bind(this)} container style={{ alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                <Grid item style={{ color: 'white', cursor: 'pointer' }}>
                  Forgot Password?
                </Grid>
              </Grid>

              <Grid container style={{ alignItems: 'center', justifyContent: 'center', marginTop: 20 }}>
                <Grid item style={{ color: 'white' }}>
                  Not a member yet?
                </Grid>
              </Grid>
              <Button
                type="button"
                fullWidth
                variant="contained"

                color="primary"
                onClick={this.signupClicked.bind(this)}
                style={{ background: 'linear-gradient(to right bottom, #51abc6, #0d3279)' }}

              >
                SignUp Now
              </Button>



              <Grid container style={{ alignItems: 'center', justifyContent: 'center' }}>
                <Grid item style={{ color: 'white' }}>
                  Product of Software Goldsmith Inc.
                </Grid>
              </Grid>







            </form>
          </div>

        </Container>






      </div>
    )
  }
}

export default withStore(LoginScreen);

const styles = {

  loginScreen: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    background: 'linear-gradient(to right bottom, #51abc6, #0d3279)'
  },

}

