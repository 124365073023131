import React, { Component } from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom';
import MainScreen from './screens/MainScreen';
import LoginScreen from './screens/LoginScreen';
import SignUpScreen from './screens/SignUpScreen';
import socketIOClient from "socket.io-client";

import { createStore } from "@spyna/react-store";



const baseURL = "https://auth.talkzee.net:4443/";
//const baseURL = "https://auth.talkzee.net/"

var socket = socketIOClient(baseURL, { transports: ['polling'] });
//const socket = socketIOClient.connect(baseURL);




function App() {

  return (
    <BrowserRouter>
      <div>

        <Route exact={true} path='/' render={
          props =>
            <LoginScreen socket={socket} baseURL={baseURL} />
        } />

        <Route exact={true} path='/signup' render={
          props =>
            <SignUpScreen socket={socket} baseURL={baseURL} />
        } />

        <Route path="/dashboard" render={
          props =>
            <MainScreen socket={socket} baseURL={baseURL} />
        } />


      </div>
    </BrowserRouter>
  );
}

const initialValue = {
  loggedIn: "",
  name: ""
};


export default createStore(App, initialValue);
