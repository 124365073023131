import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import React, { Component } from "react";

// ...

const style = {
    width: "100%",
    height: "100%",
};
const containerStyle = {
    position: "relative",
    width: "100%",
    height: "100%",
};
class MapContainer extends Component {
    render() {
        if (this.props.locationData.length !== 0 && this.props.locationData[0].currentLat !== '0' && this.props.locationData[0].currentLng !== '0') {
            return (
                <div style={{ width: 600, height: 200 }}>
                    <Map
                        google={this.props.google}
                        style={style}
                        initialCenter={{
                            lat: parseFloat(this.props.locationData[0].currentLat),
                            lng: parseFloat(this.props.locationData[0].currentLng),
                        }}
                        zoom={10}
                        onClick={() => { }}
                    >
                        {
                            this.props.locationData.map((location, index) => {
                                return (
                                    <Marker
                                        key={index}
                                        name={location.name}
                                        title={location.name}
                                        position={{ lat: parseFloat(location.currentLat), lng: parseFloat(location.currentLng) }}
                                    >

                                    </Marker>
                                )
                            })
                        }

                    </Map>
                </div>
            );
        }
        else {
            return <div style={{ marginTop: 10, padding: 10 }}>Location not avaiable.{this.props.mapShowUserLocation ? 'Please make sure your location permission is enabled and try again' : 'This user has not allowed his location to be shared.'} </div>
        }
    }
}

const LoadingContainer = (props) => <div>Loading ...</div>;

const GoogleMap = GoogleApiWrapper({
    apiKey: 'AIzaSyCeTj35wQbrlhbFFWjd_ppsfrAXX1NgKiA',
    LoadingContainer: LoadingContainer,
})(MapContainer);

export default GoogleMap;








