import React, { Component } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import logo from '../assets/icon-512.png'
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';


import Container from '@material-ui/core/Container';


import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/dist/style.css'
import { withStore } from "@spyna/react-store";




class SignUpScreen extends Component {

    
    constructor(props) {
        
        super(props)

        this.state = {
          loggedIn:this.props.store.get("loggedIn"),
          name:'',
          phoneInput:'',
          pass:'',
          socket:this.props.socket,
        }    
    }
        componentDidMount(){
        document.title = "TalkZee - SignUp"
        this.state.socket.on(
            'signup',
            function(data) {
              if (data.type == 'signup') {
                 if(data.success==true){
                    console.log(data)
                    this.props.store.set('loggedIn', data.mobileNumber)
                      .then(() => {
                        localStorage.setItem('stateOfMyApp', JSON.stringify(this.props.store.getState()));
                      })
                    this.props.store.set('name', data.name)
                      .then(() => {
                        localStorage.setItem('stateOfMyApp', JSON.stringify(this.props.store.getState()));
                    })
                    console.log(this.props.store.getState())
                    window.location.href="/dashboard"
                    

                    // this.props.history.push({
                    //     pathname: '/dashboard',
                    //     state: { loggedIn:data.mobileNumber,name:data.name,baseURL:this.baseURL,socket:socket}
                    //   })
                 }
                 else{
                   alert("Inavlid username and password combination! Please try again")
                 }
    
              }
            }.bind(this)
          );
    }
    signupClicked(){
        var phoneInput=this.state.phoneInput
        phoneInput=phoneInput.replace("+", "")
        phoneInput=phoneInput.replace("(","")
        phoneInput=phoneInput.replace(")","")
        phoneInput=phoneInput.replace("-","")
        phoneInput=phoneInput.replace(/\s/g,'')
        this.state.socket.send({
          type: 'signup',
          mobileNumber: phoneInput,
          pushToken:"",
          password:this.state.pass,
          name:this.state.name,
        });
      }

render(){
    return (
    <div style={styles.loginScreen}>
    <Container style={{
        position: 'absolute', left: '50%', top: '30%',
        transform: 'translate(-50%, -50%)'
    }}
    component="main" maxWidth="xs">
      <CssBaseline />
      <div >
      <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
          <img src={logo} style={{width:100}} alt="Logo" />
          </div>
        
        <Typography component="h1" variant="h5" style={{color:'white'}}>
          Sign Up
        </Typography>
        <form  noValidate>
        <PhoneInput 
        style={{width:'100%',marginBottom: '10px',}}
        defaultCountry={'us'} 
        value={this.state.phoneInput} 
        onChange={phoneInput => this.setState({ phoneInput })} 
        preferredCountries={['us','au','in']}
        />
        <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="name"
              label="Name"
              type="text"
              id="name"
              variant="filled"
              defaultValue={this.state.name}
              onChange={event => {
                const { value } = event.target;
                this.setState({ name: value });
              }}
              onChangeText={name => this.setState({ name })}
              style={{backgroundColor:'white',borderRadius:'5px'}}
            />
        
        <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              variant="filled"
              defaultValue={this.state.pass}
              onChange={event => {
                const { value } = event.target;
                this.setState({ pass: value });
              }}
              onChangeText={pass => this.setState({ pass })}             
              style={{backgroundColor:'white',borderRadius:'5px'}}
            />

          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <Button
            type="button"
            fullWidth
            variant="contained"
           
            color="primary"
            onClick={this.signupClicked.bind(this)}
	    style={{background: 'linear-gradient(to right bottom, #51abc6, #0d3279)'}}           
            
          >
            Continue
          </Button>
          { <Grid container style={{alignItems: 'center',justifyContent: 'center'}}>
            <Grid item style={{color:'white'}}>
                Product of Software Goldsmith Inc.
            </Grid>
          </Grid> }







        </form>
      </div>
      
    </Container>
  





      </div>
    )
  }
}

export default withStore(SignUpScreen);

const styles = {
    
    loginScreen:{
       position: 'absolute', 
       width: '100%' ,
       height: '100%',
       alignItems: 'center',
       background: 'linear-gradient(to right bottom, #51abc6, #0d3279)'
    },
   
  }


